<template>
  <div>
    <img
      alt="Xcapism Learning"
      src="../assets/logo.png"
      height="70"
      class="cornerlogo"
    />
  </div>
  <div>
    <div v-if="this.$parent.gameSettings.dev == true" class="devTools inline">
      <div class="inline">
        <button class="" type="submit" @click="PreviousStage()">
          Previous
        </button>
      </div>
      <div class="inline">
        <p>{{ this.$parent.stage }}</p>
      </div>
      <button class="" type="submit" @click="NextStage()">Next</button>
    </div>

    <div
      v-if="this.$parent.backButton.includes(this.$parent.stage) == true"
      class="backBtn inline"
    >
      <div class="inline">
        <button class="backBtnStype" type="submit" @click="PreviousStage()">
          Rewatch Video
        </button>
      </div>
    </div>

    <img
      v-if="
        this.$parent.gameSettings.showRightLogo === true &&
        this.$parent.stage.includes('stage') === true
      "
      alt="Xcapism Learning"
      :src="this.$parent.logoData[this.$parent.gameSettings.cornerLogoFile]"
      height="100"
      class="cornerlogoRight"
    />
  </div>
</template>
<script>
export default {
  name: "DevTools",
  props: {
    msg: String,
    dbid: String,
    text: String,
    button: String,
  },
  data() {},
  mounted() {},
  methods: {
    NextStage() {
      var video = document.getElementById("vidId");
      if (video) {
        video.currentTime = 0;
        video.load();
      }
      this.$emit("updatestage", "");
    },
    PreviousStage() {
      var video = document.getElementById("vidId");
      if (video) {
        video.currentTime = 0;
        video.load();
      }

      this.$emit("previousstage", "");
    },
  },
};
</script>