<template>
  <div class="outer">
    <div class="middle">
      <div class="center">
        <div class="frame">
          <div class="innerframe">
            <div v-html="$parent.language[this.gameName].body"></div>

            <table class="messageTable">
              <tr>
                <th>{{ $parent.language[this.gameName].messageNo }}</th>
                <th>{{ $parent.language[this.gameName].YourChoice }}</th>
                <th>{{ $parent.language[this.gameName].CorrectChoice }}</th>
              </tr>
              <tr>
                <td>{{ $parent.language[this.gameName].Message1 }}</td>
                <td>
                  <template v-if="info.message1Chosen == ''">
                    <div class="button-wrap">
                      <div
                        class="button-default button-slanted button-slanted--left"
                      >
                        <span
                          class="button-slanted-content"
                          @click="checkans('s', 1)"
                          >Safe</span
                        >
                      </div>

                      <div
                        class="button-default button-slanted button-slanted--right"
                      >
                        <span
                          class="button-slanted-content"
                          @click="checkans('d', 1)"
                          >Dodgy</span
                        >
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="info.message1Chosen == 's'">
                      <span class="msgAnswer msgAnswerSafe">Safe</span>
                    </template>
                    <template v-else>
                      <span class="msgAnswer msgAnswerDoggy">Doggy</span>
                    </template>
                  </template>
                </td>
                <td>{{ this.info.message1answer }}</td>
              </tr>

              <tr>
                <td>{{ $parent.language[this.gameName].Message2 }}</td>
                <td>
                  <template v-if="info.message2Chosen == ''">
                    <div class="button-wrap">
                      <div
                        class="button-default button-slanted button-slanted--left"
                      >
                        <span
                          class="button-slanted-content"
                          @click="checkans('s', 2)"
                          >Safe</span
                        >
                      </div>

                      <div
                        class="button-default button-slanted button-slanted--right"
                      >
                        <span
                          class="button-slanted-content"
                          @click="checkans('d', 2)"
                          >Dodgy</span
                        >
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="info.message2Chosen == 's'">
                      <span class="msgAnswer msgAnswerSafe">Safe</span>
                    </template>
                    <template v-else>
                      <span class="msgAnswer msgAnswerDoggy">Dodgy</span>
                    </template>
                  </template>
                </td>
                <td>{{ this.info.message2answer }}</td>
              </tr>

              <tr>
                <td>{{ $parent.language[this.gameName].Message3 }}</td>
                <td>
                  <template v-if="info.message3Chosen == ''">
                    <div class="button-wrap">
                      <div
                        class="button-default button-slanted button-slanted--left"
                      >
                        <span
                          class="button-slanted-content"
                          @click="checkans('s', 3)"
                          >Safe</span
                        >
                      </div>

                      <div
                        class="button-default button-slanted button-slanted--right"
                      >
                        <span
                          class="button-slanted-content"
                          @click="checkans('d', 3)"
                          >Dodgy</span
                        >
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="info.message3Chosen == 's'">
                      <span class="msgAnswer msgAnswerSafe">Safe</span>
                    </template>
                    <template v-else>
                      <span class="msgAnswer msgAnswerDoggy">Dodgy</span>
                    </template>
                  </template>
                </td>
                <td>{{ this.info.message3answer }}</td>
              </tr>

              <!-- <tr>
                <td>{{ $parent.language[this.gameName].Message2 }}</td>
                <template v-if="this.info.message2answer != null">
                  <template v-if="this.info.message2answer == 0">
                    <td>{{ $parent.language[this.gameName].safe }}</td>
                  </template>
                  <template v-else>
                    <td>{{ $parent.language[this.gameName].notsafe }}</td>
                  </template>
                  <td>{{ $parent.language[this.gameName].notsafe }}</td>
                </template>
                <template v-else>
                  <td>?</td>
                  <td>?</td>
                </template>
              </tr>

              <tr>
                <td>{{ $parent.language[this.gameName].Message3 }}</td>
                <template v-if="this.info.message3answer != null">
                  <template v-if="this.info.message3answer == 0">
                    <td>{{ $parent.language[this.gameName].safe }}</td>
                  </template>
                  <template v-else>
                    <td>{{ $parent.language[this.gameName].notsafe }}</td>
                  </template>
                  <td>{{ $parent.language[this.gameName].safe }}</td>
                </template>
                <template v-else>
                  <td>?</td>
                  <td>?</td>
                </template>
              </tr> -->
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../assets/form.css";
</style>

<script>
import { callApi } from "../plugins/callApi.js";

export default {
  name: "messagesGameManual",
  props: {
    dbid: String,
    gameName: String,
  },
  data() {
    return {
      teamName: "",
      done: false,
      info: {
        message1answer: "?",
        message1Chosen: "",
        message2answer: "?",
        message2Chosen: "",
        message3answer: "?",
        message3Chosen: "",
      },
    };
  },
  methods: {
    async checkans(answer, number) {
      console.log(answer);
      console.log(number);
      switch (number) {
        case 1:
          this.info.message1Chosen = answer;
          this.info.message1answer = "Dodgy";
          if (answer == "s") {
            this.$emit("addPenalty", "");
          }
          break;
        case 2:
          this.info.message2Chosen = answer;
          this.info.message2answer = "Dodgy";
          if (answer == "s") {
            this.$emit("addPenalty", "");
          }
          break;
        case 3:
          this.info.message3Chosen = answer;
          this.info.message3answer = "Safe";
          if (answer == "d") {
            this.$emit("addPenalty", "");
          }
          break;
      }
      this.isDOne();
    },
    async isDOne() {
      if (
        this.info.message1answer != "?" &&
        this.info.message2answer != "?" &&
        this.info.message3answer != "?"
      ) {
        if (this.done == false) {
          //  window.clearInterval(intervalId);
          this.$emit("updatestage", "");
          this.done = true;
        }
      }
    },
  },
  async mounted() {
    document.querySelector(
      "#app > div.outer > div > div > div"
    ).style.borderColor = this.$parent.gameSettings.containerColor;

    this.$emit("pauseTimer", 0);

    // axios.post(process.env.VUE_APP_ip + '/messagestart', { "dbid": this.dbid }) //start the message game

    await callApi("updateprogress", this.license, {
      gameName: "startMessages",
      dbid: this.dbid,
    });
    // let intervalId = window.setInterval(async () => {
    //   var gameData = await callApi("messagesgame", this.license, { "gameName": process.env.VUE_APP_gameName, "dbid": this.dbid, "type": "check" })
    //   console.log("====!!!")
    //   console.log(gameData)
    //   console.log("====")
    //   this.info = gameData.body

    //   }, 1000);
  },
};
</script>


